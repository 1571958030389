<template>
  <div>
    <v-form>
      <p class="mb-3">
        Match the correct response (treatment) for each type of injury/incident that might occur in
        the lab:
      </p>

      <v-simple-table class="pl-4">
        <tr v-for="(question, index) in questions" :key="'pt-1-' + index">
          <td>
            {{ question.text }}
          </td>
          <td>
            <v-select
              v-model="inputs[question.inputName]"
              :items="optionsShuffled"
              class="my-n1"
              label="Select Option"
              item-text="text"
              item-value="value"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </td>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemMcMPL4_Q2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswerCut: null,
        studentAnswerSplash: null,
        studentAnswerBurn: null,
        studentAnswerSmallSpill: null,
        studentAnswerIll: null,
        studentAnswerSevereCut: null,
        studentAnswerLargeSpill: null,
      },
      questions: [
        {
          text: 'a) Small skin cut',
          inputName: 'studentAnswerCut',
        },
        {
          text: 'b) Chemical splash in eyes',
          inputName: 'studentAnswerSplash',
        },
        {
          text: 'c) Heat burn',
          inputName: 'studentAnswerBurn',
        },
        {
          text: 'd) Small chemical spill on your hand',
          inputName: 'studentAnswerSmallSpill',
        },
        {
          text: 'e) Feeling dizzy or ill',
          inputName: 'studentAnswerIll',
        },
        {
          text: 'f) Severe skin cut',
          inputName: 'studentAnswerSevereCut',
        },
        {
          text: 'g) Large chemical spill on you',
          inputName: 'studentAnswerLargeSpill',
        },
      ],
      options: [
        {
          text: 'Call TA, rinse, apply bandage',
          value: 'a',
        },
        {
          text: 'Call TA, apply direct pressure, phone 88',
          value: 'b',
        },
        {
          text: 'Call TA, rinse with water 10 minutes, TA uses first aid solution if needed',
          value: 'c',
        },
        {
          text: 'Call TA, use shower',
          value: 'd',
        },
        {
          text: 'Call TA, apply cool water immediately',
          value: 'e',
        },
        {
          text: 'Call TA, use eyewash for 10 minutes, phone 88 if necessary',
          value: 'f',
        },
        {
          text: 'Sit down, call TA, place your head between your knees, leave lab room if needed',
          value: 'g',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
